<template>
  <Teleport to="body">
    <Transition name="fade" appear>
      <div v-if="show" class="fixed inset-0 z-50 bg-black/40 backdrop-blur-lg" />
    </Transition>
    <Transition appear :name="variant === 'sheet' && !isMd ? 'slideUp' : 'fade'">
      <div
        v-if="show"
        class="fixed inset-0 z-50 flex overflow-auto md:p-8px"
        :class="variant === 'basic' && 'p-4px'"
      >
        <div
          class="flex w-full flex-col items-center"
          :class="[
            {
              'h-full flex-1 md:h-fit md:flex-none':
                variant === 'fullscreen' || variant === 'with-topbar'
            },
            variant === 'sheet' ? 'mt-auto md:m-auto' : 'm-auto'
          ]"
        >
          <div
            ref="modalRef"
            class="relative flex w-full flex-col"
            :class="[
              (variant === 'fullscreen' || variant === 'with-topbar') && 'flex-1 md:flex-none',
              variant === 'with-topbar'
                ? 'max-h-full overflow-hidden bg-neutral-50 md:h-[616px] md:max-h-[616px] md:w-[952px] md:overflow-visible'
                : 'bg-white sm:w-auto sm:min-w-[600px]',
              slots.header && 'overflow-hidden md:overflow-auto',
              steps && 'md:mb-8px',
              roundedClass
            ]"
            v-bind="$attrs"
          >
            <div
              v-if="steps"
              class="absolute left-0 right-0 top-0 transition-opacity duration-500 md:hidden"
              :class="showSteps ? 'opacity-100' : 'opacity-0'"
            >
              <ProgressIndicator :progress="(steps.current / steps.total) * 100 - 10" />
            </div>
            <button
              v-if="showClose"
              class="absolute z-[1] md:right-6px md:top-6px"
              :class="variant === 'with-topbar' ? 'right-[18px] top-[18px]' : 'right-4px top-4px'"
              type="button"
              @click="$emit('on-close', { outside: false })"
            >
              <Icon
                name="close-general"
                :class="variant === 'with-topbar' ? 'h-6px w-6px md:h-8px md:w-8px' : 'h-8px w-8px'"
              />
            </button>
            <div v-if="variant === 'with-topbar'" class="flex h-15px items-center md:hidden">
              <Logo class="lg:ml-0px ml-4px md:ml-10px" />
            </div>
            <div
              v-if="slots.header"
              class="px-4px py-5px text-heading-22-medium-px md:p-10px"
              :class="scrollY > 0 && 'border-b border-neutral-100'"
            >
              <slot name="header"></slot>
            </div>
            <div
              ref="contentRef"
              class="max-h-full overflow-auto px-4px md:overflow-visible"
              :class="[
                variant === 'with-topbar'
                  ? 'max-h-full flex-1 overflow-auto rounded-t-[20px] bg-white pt-5px md:p-0'
                  : [
                      'pb-6px pt-3px md:px-10px',
                      slots.header ? 'md:pb-10px md:pt-0' : 'md:py-10px'
                    ],
                roundedClass,
                contentClass
              ]"
            >
              <slot />
            </div>
          </div>
          <StepIndicator
            v-if="steps"
            :current="steps.current"
            :total="steps.total"
            class="hidden transition-opacity duration-500 md:flex"
            :class="showSteps ? 'opacity-100' : 'opacity-0'"
          />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import screens from '#tailwind-config/theme/screens'

export interface ModalOnCloseEvent {
  outside: boolean
}

export type ModalVariant = 'basic' | 'fullscreen' | 'sheet' | 'with-topbar'

interface ModalProps {
  show?: boolean
  showClose?: boolean
  steps?: {
    current: number
    total: number
  }
  showSteps?: boolean
  variant?: ModalVariant
  contentClass?: string
}

const props = withDefaults(defineProps<ModalProps>(), {
  showClose: true,
  variant: 'basic'
})

defineOptions({
  inheritAttrs: false
})

defineEmits<{
  'on-close': [event: ModalOnCloseEvent]
}>()

const breakpoints = useBreakpoints(screens)
const isMd = breakpoints.greater('md')
const contentRef = ref<HTMLElement | null>(null)
const modalRef = ref<HTMLElement | null>(null)

const { bodyLock, bodyUnlock } = useBodyLock()
const slots = useSlots()
const { y: scrollY } = useScroll(contentRef)

const roundedClass = computed(() => [
  'md:rounded-[20px]',
  {
    'rounded-[20px]': props.variant === 'basic',
    'rounded-t-[20px]': props.variant === 'sheet',
    'sm:rounded-[20px]': props.variant === 'fullscreen'
  }
])

/* // TODO: removed for now, because it doesen't seem to work properly with double modals
onClickOutside(modalRef, () => {
  emit('on-close', { outside: true })
})
*/

watch(
  () => props.show,
  isShow => {
    if (isShow) {
      bodyLock()
    } else {
      bodyUnlock()
    }
  }
)

onUnmounted(bodyUnlock)
</script>
