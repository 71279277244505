<template>
  <div class="relative flex w-fit items-center justify-center gap-1px">
    <div
      v-for="(_, i) in steps"
      :key="i"
      class="h-2.5px rounded-full transition-all duration-300"
      :class="i + 1 === current ? 'w-7px bg-white' : 'w-2.5px bg-neutral-600'"
    />
  </div>
</template>

<script setup lang="ts">
interface StepIndicatorProps {
  current: number
  total: number
}

const props = defineProps<StepIndicatorProps>()

const steps = computed(() => Array.from({ length: props.total }))
</script>
